import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

interface Props {}
interface State {
  notices: any;
  widhdraws: any;
}

export class Footer extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      widhdraws: [],
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일 22시28분";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr },
      { id: "jvd****", price: "83,000,000 원", date: yesterdayStr },
      { id: "vhr**", price: "82,000,000 원", date: yesterdayStr },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr },
      { id: "df****", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  RenderNotice = () => {
    let notices: any = [];

    this.state.notices.map((info: any, index: any) => {
      if (index < 5) {
        notices.push(info);
      }
    });

    return (
      <>
        {notices.map((info: any, index: any) => (
          <tr>
            <td>
              <span className="text-yellow">[공지]</span> {info.title}
            </td>
            <td className="date">{ConvertDate(info.regdate)}</td>
          </tr>
        ))}
      </>
    );
  };

  RenderWidhdraws = () => {
    return (
      <>
        {this.state.widhdraws.map((info: any, index: any) => (
          <li className="tr">
            <div className="user">
              <span className="bullet"></span>
              <span>{info.id}</span>
            </div>
            <div className="amount">
              <span>{info.price}</span>
            </div>
            <div className="date">
              <span>{info.date}</span>
            </div>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <>
        <strong>
          <strong>
            <div className="quick">
              <div className="top" style={{ display: "block" }}>
                <a href="javascript:;">
                  <img src="/include/images/quick_top-btn.png" alt="" />
                </a>
              </div>
            </div>

            <div id="footer">
              <div className="footer-cont">
                <div className="footer-cont-box">
                  <div className="footer-icon">
                    <span>
                      <img src="/include/images/ft_img1.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img2.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img3.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img4.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img5.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img6.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img7.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img8.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img9.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img10.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img11.gif" alt="" />
                    </span>
                    <br />
                    <span>
                      <img src="/include/images/ft_img12.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img13.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img14.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img15.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img16.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img17.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img18.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img19.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img20.gif" alt="" />
                    </span>
                    <br />
                    <span>
                      <img src="/include/images/ft_img21.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img22.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img23.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img24.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img25.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img26.gif" alt="" />
                    </span>
                    <span>
                      <img src="/include/images/ft_img27.gif" alt="" />
                    </span>
                  </div>
                  <div className="footer-txt">
                    <p>
                      COPYRIGHT 2021 <strong>FUN</strong>. ALL RIGHTS RESERVED.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </strong>
        </strong>
      </>
    );
  }
}
