import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";
import { ConvertDate } from "../utility/help";
import Marquee from "react-fast-marquee";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  SetCookie: (name: string, data: any) => any;
  updateContentPage: (page: string) => any;
  updateNote: (note: any) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
  notices: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
      notices: [],
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
      let time = new Date().getTime() / 1000;

        for (;;) {
          let now = new Date().getTime() / 1000;

          
          const localTime = localStorage.getItem('UpdateNote1');
          if (localTime != null && now - Number(localTime) < 10) {
            await sleep(1000);
            continue
          }

          
          if (now - Number(time) < 10) {
            await sleep(1000);
            continue;
          }
          localStorage.setItem('UpdateNote1', now.toString());


          this.handleUpdateNote();

          await sleep(30000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
          });

          this.props.updateContentPage(popupView.note);
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    console.log(user)
    console.log(user)
    console.log(user)

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="header-tp-rt login-before">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    className="btn orange sh login"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.login }); //
                    }}
                  >
                    <span>
                      <i className="iconfont"></i>로그인
                    </span>
                  </a>
                  <a
                    href="javascript:;"
                    className="btn gray sh join go-recommender-code"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg }); //
                    }}
                  >
                    <span>
                      <i className="iconfont"></i>회원가입
                    </span>
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </li>
              </ul>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="header-tp-rt login-after">
            <ul>
              <li>
                <span className="icon iconfont"></span>
                <b className="white">{this.props.user.id}</b> 님,{" "}
                <span>
                  <img src="/include/images/message.gif" alt="" />
                </span>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.props.updateNote(this.state.note);
                    this.props.updateContentPage(popupView.note);
                  }}
                >
                  쪽지 <span className="white">{this.state.noteCount}</span> 통
                </a>
              </li>
              <li>
                <span className="icon iconfont"></span>보유머니:{" "}
                <span className="yellow" id="myCash">
                  {" "}
                  {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                </span>{" "}
                원{" "}
            
              </li>
              <li>
                <span className="icon iconfont"></span>
                <a href="javascript:;">
                  금고머니:{" "}
                  <span className="red" id="tmpPoint">
                    {ConverMoeny(this.props.user.point)}
                  </span>{" "}
                  {/* Point <span className="icon iconfont gray"></span> */}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  className="btn orange-line sh logout"
                  onClick={() => {
                    this.props.tryLoginOut();
                  }}
                >
                  <span>로그아웃</span>
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    };

    return (
      <>
        <div id="header">
          <div className="notice">
            <p className="tit">
              <span>
                <b>NOTICE</b>
              </span>
            </p>
            <div className="main-visual">
              <div
                className="bx-wrapper"
                style={{ maxWidth: "100%", margin: "0px auto" }}
              >
                <div
                  className="bx-viewport"
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    position: "relative",
                    height: "33px",
                  }}
                >
                  <ul
                    className="bxslider"
                    style={{
                      width: "auto",
                      position: "relative",
                      transitionDuration: "0s",
                      transform: "translate3d(0px, -33px, 0px)",
                    }}
                  >
                    {this.state.notices.map((info: any, index: any) => (
                      <li
                        key={`notices_${index}`}
                        style={{
                          float: "none",
                          listStyle: "none",
                          position: "relative",
                          width: "400px",
                        }}
                        className="bx-clone"
                      >
                        <a >★ {info.title} ★</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {RenderLogin()}
        </div>

        {RenderPopup()}
        {/* {RendePopup()} */}
      </>
    );
  }
}
