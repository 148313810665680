import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {}

interface State {
  helps: any;
  mode: string;
  title: string;
  contents: string;
  status: string;
  regdate: string;
  ref: string;
  maxCount: number;
  index: number;
  no: string;
  page: number;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.list,
      title: "",
      contents: "",
      status: "",
      regdate: "",
      ref: "",
      maxCount: 0,
      index: 0,
      no: "",
      page: 1,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps, maxCount: data.maxCount });
      }
    });
  };

  prevHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: index,
            no: info.no,
          });
        }
      });
    }
  };

  nextHelp = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.helps.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            status: info.status,
            ref: info.ref != null ? info.ref.contents : "",
            index: index,
            no: info.no,
          });
        }
      });
    }
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();

                this.setState({
                  mode: helpView.list,
                });
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    let helps: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor((maxCount - 1) / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.helps.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        helps.push(info);
      }
    });

    console.log("## helps");
    console.log(helps);

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderRef = () => {
      if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      }
    };

    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <div className="table-dl">
            <ul>
              {helps.map((info: any, index: any) => (
                <>
                  <li>
                    <div className="box">
                      <dl>
                        <dt>
                          <div className="notice-style">
                            <p className="notice-tit">
                              <span>{info.no}</span>
                            </p>
                          </div>
                          <p className="title">
                            <a
                              href="javascript:;"
                              onClick={() => {
                                service.do_help_message_read(info._id);
                                this.setState({
                                  title: info.title,
                                  contents: info.contents,
                                  regdate: info.regdate,
                                  status: info.status,
                                  ref:
                                    info.ref != null ? info.ref.contents : "",
                                  mode: helpView.view,
                                  index: index,
                                  no: info.no,
                                });
                              }}
                            >
                              {info.title}
                            </a>
                          </p>
                        </dt>
                        <dd className="clearfix">
                          <p className="day-box">
                            {ConvertDate(info.regDate)}&nbsp;&nbsp;&nbsp;
                            {HelpStatus(info.status)}
                          </p>
                        </dd>
                      </dl>
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>

          <div className="sub-paging" style={{ width: "auto" }}>
            {pages.map((page: any) => {
              if (page.no === this.state.page) {
                return (
                  <>
                    <a
                      className="on"
                      onClick={() => {
                        this.setState({
                          page: page.no,
                        });
                      }}
                    >
                      <b> {page.no} </b>
                    </a>{" "}
                  </>
                );
              } else {
                return (
                  <>
                    <a
                      className=""
                      onClick={() => {
                        this.setState({
                          page: page.no,
                        });
                      }}
                    >
                      <b> {page.no} </b>
                    </a>{" "}
                  </>
                );
              }
            })}
          </div>

          <div className="center-btn">
            <a
              href="javascript:;"
              className="btn orange sh w130"
              onClick={() => {
                this.setState({
                  mode: helpView.write,
                  title: "",
                  contents: "",
                });
              }}
            >
              <span>1:1문의</span>
            </a>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <div className="table-view">
            <div className="box">
              <dl className="header">
                <dt>{this.state.title}</dt>
                <dd>{ConvertDate(this.state.regdate)}</dd>
                <dd>{HelpStatus(this.state.status)}</dd>
              </dl>
              <div className="txt-box">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.contents }}
                ></div>
                {this.state.ref !== undefined && (
                  <>
                    <p>[답변]</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.ref }}
                    ></div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="center-btn">
            <a
              href="javascript:;"
              className="btn orange sh w130"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              <span>목록</span>
            </a>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="customer-service-write ">
            <dl>
              <dd>
                <input
                  type="text"
                  placeholder="제목을 입력 하세요."
                  value={this.state.title}
                  onChange={(e) =>
                    this.setState({
                      title: e.target.value,
                    })
                  }
                />
              </dd>
              <dd>
                <textarea
                  name="content"
                  cols={30}
                  rows={10}
                  maxLength={300}
                  placeholder="내용을 입력하세요"
                  value={this.state.contents}
                  onChange={(e) =>
                    this.setState({
                      contents: e.target.value,
                    })
                  }
                ></textarea>
              </dd>
            </dl>
          </div>

          <div className="center-btn">
            <a
              href="javascript:;"
              className="btn orange sh w130"
              onClick={() => {
                this.handleSaveHelp(this.state.title, this.state.contents);
              }}
            >
              <span>문의하기</span>
            </a>
            &nbsp;
            <a
              href="javascript:;"
              className="btn orange sh w130"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              <span>취소</span>
            </a>
          </div>
        </>
      );
    };
    return (
      <>
        <div id="container">
          <div className="sub-container">
            <div className="min-box">
              <h3 className="sub-title">고객센터</h3>

              {RenderList()}
              {RenderView()}
              {RenderWrite()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
