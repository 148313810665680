import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleRollingToBalance = () => {
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <>
        <div id="container">
          <div className="sub-container">
            <div className="min-box">
              <h3 className="sub-title">
                콤프 <span></span>
              </h3>
              <div className="application-style">
                <dl>
                  <dt>콤프머니</dt>
                  <dd>
                    <input
                      type="text"
                      value={ConverMoeny(this.props.user.rolling)}
                      readOnly
                    />
                  </dd>
                </dl>
    
                <dl>
                  <dt>요청금액</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="0"
                      value={this.state.balance}
                      onFocus={() => this.setState({ balance: "" })}
                      onChange={(e) =>
                        this.setState({
                          balance: e.target.value,
                        })
                      }
                    />
                    <span className="btn-all">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 10000}`,
                          });
                        }}
                      >
                        1만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 50000}`,
                          });
                        }}
                      >
                        5만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 100000}`,
                          });
                        }}
                      >
                        10만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 500000}`,
                          });
                        }}
                      >
                        50만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 1000000}`,
                          });
                        }}
                      >
                        100만원
                      </a>
                      <a
                        href="javascript:;"
                        className="first"
                        onClick={() => {
                          this.setState({ balance: "0" });
                        }}
                      >
                        초기화
                      </a>
                    </span>
                  </dd>
                </dl>
              </div>

              <div className="center-btn">
                <a
                  href="javascript:;"
                  className="btn orange sh w240"
                  onClick={this.handleRollingToBalance}
                >
                  <span>콤프전환</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    
    );
  }
}
