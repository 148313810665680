import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import Paper from "@material-ui/core/Paper";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  background: #1e1f21;
  background: linear-gradient(to bottom, #641607, #641607);
  position: relative;
  border-top: 0;
  border-bottom: 2px solid #871e09;
`;

const CustomTableCell2 = styled(TableCell)`
  text-align: center;
  color: #adadad;
  font-size: 14px;
  height: 50px;
  border-bottom: 0px solid #404447;
  background: #1e1f21;
  color: #ffffff;
`;

const CustomTableCell3 = styled(TableCell)`
  background: #1e1f21;
  color: #ffffff;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {row.isRead === "y" ? "확인" : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell3
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>
          </Collapse>
        </CustomTableCell3>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  note: any;
}

interface State {
  helps: any;
  note: any;
  mode: string;
  regdate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
  status: string;
  index: number;
  no: string;
  page: number;
  maxCount: number;
  id: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      note: this.props.note,
      mode: noteView.list,
      title: "",
      contents: "",
      regdate: "",
      isRead: false,
      text: "",
      status: "",
      index: 0,
      no: "",
      page: 1,
      maxCount: 0,
      id: "",
    };
  }

  componentDidMount() {
    this.handleGetNotes();
  }

  handleGetNotes = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          note: data.note,
          maxCount: data.maxCount,
        });
      }
    });
  };

  prevNote = (index: number) => {
    console.log("index : " + index);
    if (index !== 0) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index - 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  nextNote = (index: number) => {
    console.log("index : " + index);
    if (index !== this.state.maxCount - 1) {
      this.state.note.map((info: any, idx: any) => {
        if (idx === index + 1) {
          console.log("index : " + index);
          this.setState({
            title: info.title,
            contents: info.contents,
            regdate: info.regdate,
            isRead: info.isRead,
            text: info.text,
            index: idx,
            no: info.no,
            status: info.isRead === "y" ? "확인" : "미확인",
          });
        }
      });
    }
  };

  render() {
    let note: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.note.length;
    let totPage = Math.floor((maxCount - 1) / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;
    let prevPage = this.state.page - 1;
    let nextPage = this.state.page + 1;

    if (prevPage < 1) {
      prevPage = 1;
    }

    if (nextPage > totPage) {
      nextPage = totPage;
    }

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.note.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        note.push(info);
      }
    });

    // let tmpNote: any = [];
    // tmpNote.push({
    //   no: "1",
    //   title: "test1",
    //   regdate: "2022-01-01",
    //   isRead: "n",
    //   text: "testtest1",
    //   _id: "1",
    // });
    // tmpNote.push({
    //   no: "2",
    //   title: "test2",
    //   regdate: "2022-01-01",
    //   isRead: "n",
    //   text: "testtest2",
    //   _id: "2",
    // });
    // tmpNote.push({
    //   no: "3",
    //   title: "test3",
    //   regdate: "2022-01-01",
    //   isRead: "n",
    //   text: "testtest3",
    //   _id: "3",
    // });
    // tmpNote.push({
    //   no: "4",
    //   title: "test4",
    //   regdate: "2022-01-01",
    //   isRead: "n",
    //   text: "testtest4",
    //   _id: "4",
    // });
    // tmpNote.push({
    //   no: "5",
    //   title: "test5",
    //   regdate: "2022-01-01",
    //   isRead: "n",
    //   text: "testtest5",
    //   _id: "5",
    // });

    // note = tmpNote;

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#484848" }}
            >
              <TableHead>
                <TableRow>
                  <CustomTableCell
                    align="center"
                    style={{ width: "10%" }}
                  ></CustomTableCell>
                  <CustomTableCell align="center" style={{ width: "60%" }}>
                    제 목
                  </CustomTableCell>
                  <CustomTableCell align="center" style={{ width: "15%" }}>
                    작성일
                  </CustomTableCell>
                  <CustomTableCell align="center" style={{ width: "15%" }}>
                    상태
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {note.map((row: any) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          <div className="con_box10">
            <table
              style={{ width: "98.5%", border: "0", marginLeft: "10px" }}
              cellSpacing={0}
              cellPadding={0}
            >
              <tbody>
                <tr>
                  <td style={{ height: "30px", textAlign: "right" }}>
                    <span className="view_box">글쓴이</span> 운영자
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="view_box">작성일</span>{" "}
                    {ConvertDate(this.state.regdate)}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="view_box">상태</span>{" "}
                    {ConvertDate(this.state.status)}{" "}
                  </td>
                </tr>
                <tr>
                  <td className="view1 write_title_top">{this.state.title}</td>
                </tr>
                <tr>
                  <td className="view2">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="con_box20">
            <div className="btn_wrap_center">
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({
                        mode: noteView.list,
                      });
                    }}
                  >
                    <span className="btn2_1">목록</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <div id="container">
          <div className="sub-container">
            <div className="min-box">
              <h3 className="sub-title">쪽지</h3>

              {RenderList()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
