import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import Marquee from "react-fast-marquee";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casino = "casino",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  gameView: string;
  updateUser: () => any;
  updateContentPage: (page: string) => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  gameEName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  helps: any;
  widhdraws: any;
  yesterdayStr: string;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      helps: [],
      gameCode: "",
      gameName: "",
      gameEName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
      yesterdayStr: "",
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    this.setState({ yesterdayStr: yesterdayStr });

    today.setHours(today.getHours() - 1);

    let tmpWidhdraws = [];
    let strHour =
      Number(today.getHours()) < 10 ? "0" + today.getHours() : today.getHours();
    tmpWidhdraws.push({
      time: strHour + ":15:34",
      id: "**ofg**",
      price: "140,000",
    });
    tmpWidhdraws.push({
      time: strHour + ":26:21",
      id: "**1we**",
      price: "250,000",
    });
    tmpWidhdraws.push({
      time: strHour + ":31:03",
      id: "**bsb**",
      price: "650,000",
    });
    tmpWidhdraws.push({
      time: strHour + ":43:45",
      id: "**ojs**",
      price: "450,000",
    });
    tmpWidhdraws.push({
      time: strHour + ":52:19",
      id: "**789**",
      price: "1,000,000",
    });

    this.setState({ widhdraws: tmpWidhdraws });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => {
          if (index > 9) {
            return <></>;
          }
          return (
            <a
              href="javascript:;"
              className="slot-btn gl-title-click"
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            mode: Mode.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.setState({ gameEName: info.nameEn });
                  this.setState({ mode: Mode.slot });
                  // this.handleSlotGames(info.code);
                  this.setState({ popupStatuses: popupView.game });
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="inner">
                <div className="slot-cont">
                  <img className="slot-img" src={info.mobileImg} />
                </div>
              </div>
              <p className="game_title_txt">{info.nameKo}</p>
            </a>
          );
        })}
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
        {this.state.casionos.map((info: any, index: any) => {
          if (index > 9) {
            return <></>;
          }

          return (
            <a
              href="javascript:;"
              className="slot-btn gl-title-click"
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            popupStatuses: popupView.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="inner">
                <div className="slot-cont">
                  <img className="slot-img" src={info.mobileImg} />
                </div>
              </div>
              <p className="game_title_txt">{info.nameKo}</p>
            </a>
          );
        })}
      </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== Mode.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices: any = [];
    let widhdraws = this.state.widhdraws;

    this.state.notices.map((info: any, index: any) => {
      if (index < 4) {
        notices.push(info);
      }
    });

    return (
      <>
        <div className="transaction-table">
          <div className="table-heading">
            <span className="col-xs-8">공지사항/이벤트게시판/ 주간출금</span>
            <span
              className="col-xs-4 pull-right text-right"
              onClick={() => {
                this.setState({ popupStatuses: popupView.notice }); //공지사항
              }}
            >
              전체 보기
            </span>
          </div>
          <ul
            className=" list-unstyled transaction-list"
            style={{
              width: "328px",
              height: "159.5px",
              overflow: "hidden",
            }}
            id="notice-ticker"
          >
            {notices.map((info: any, index: any) => (
              <li
                role="button"
                className="ng-scope"
                onClick={() => {
                  this.setState({ popupStatuses: popupView.notice }); //공지사항
                }}
              >
                <div className="col-xs-4 text-left">
                  <span className="ng-binding">
                    {ConvertDate(info.regdate)}
                  </span>
                </div>
                <div className="col-xs-8 text-left text-white">
                  <span className="ng-binding">{info.title}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  RenderPopup = () => {
    if (this.state.popupStatuses === popupView.game) {
      if (this.props.authenticated === false) {
        confirmAlert({
          title: "로그인",
          message: "로그인 이후 이용가능한 기능입니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ popupStatuses: popupView.none });
              },
            },
          ],
        });
        return;
      }

      return (
        <Companylist
          handleClose={this.handleClosePopup}
          handleChange={this.handleChangePopup}
          gameCode={this.state.gameCode}
          gameName={this.state.gameName}
          gameEName={this.state.gameEName}
        ></Companylist>
      );
    }

    return <div></div>;
  };

  render() {
    const authenticated = this.props.authenticated;

    let bestSlots: any = [];
    this.state.slots.map((info: any, index: any) => {
      if (index < 6) {
        bestSlots.push(info);
      }
    });

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const openBestSlot = (best: any) => {
      if (this.props.authenticated == false) {
        confirmAlert({
          title: "로그인",
          message: "로그인 이후 이용가능한 기능입니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({
                  mode: Mode.none,
                });
              },
            },
          ],
        });
        return;
      }

      if ((best === undefined ? "n" : best.used) === "y") {
        this.setState({
          gameCode: best === undefined ? "" : best.code,
        });
        this.setState({
          gameName: best === undefined ? "" : best.nameKo,
        });
        this.setState({
          gameEName: best === undefined ? "" : best.nameEn,
        });
        this.setState({ mode: Mode.slot });
        this.setState({ popupStatuses: popupView.game });
      } else {
        confirmAlert({
          title: "점검중",
          message: "현재 해당게임은 점검중입니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    };

    //

    return (
      <>
        <div id="container" className="main-container">
          <div className="main-swiper">
            <div className="img-box">
              <img
                src="/include/images/main/main-swiper-img1-01.png"
                alt=""
                className="img01"
              />
              <img
                src="/include/images/main/main-swiper-img1-02.png"
                alt=""
                className="img02"
              />
              <img
                src="/include/images/main/main-swiper-img1-03.png"
                alt=""
                className="img03"
              />
            </div>
            <div className="swiper-wrapper">
              <div className="swiper-slide clearfix">
                <div className="txt-box">
                  <h3 className="tit1">
                    세계 최초 공식 라이선스 <br />
                    No.1 슬롯 카지노
                  </h3>
                </div>
              </div>
              <div className="swiper-slide clearfix">
                <div className="txt-box">
                  <h3 className="tit1">
                    HOT SLOT <br />
                    슬롯게임사 정식제휴
                  </h3>
                </div>
              </div>
              <div className="swiper-slide clearfix">
                <div className="txt-box">
                  <h3 className="tit1">
                    BACCARAT <br />
                    작은 베팅으로 큰 배당을 얻어보세요!
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
          <div className="progressive-jackpot clearfix">
            <h3 className="tit">PROGRESSIVE JACKPOT</h3>
            <div id="odometer" className="odometer"></div>
          </div>
          <h3 className="main-title">
            <dl>
              <dt>
                <i className="icon iconfont">&#xe676;</i>
              </dt>
              <dd>
                SLOT GAME{" "}
                <span>유저들이 가장 많이 이용하는 슬롯게임 입니다.</span>
              </dd>
            </dl>
          </h3>
          <div className="img-list">
            <div className="clearfix sc-inner">{this.RenderSlotList()}</div>
          </div>
          <div className="text-center mt10">
            <a
              href="javascript:;"
              className="all-btn btn"
              onClick={() => {
                this.props.updateContentPage(popupView.slot);
              }}
            >
              <span>전체보기</span>
            </a>
          </div>
          <h3 className="main-title">
            <dl>
              <dt>
                <i className="icon iconfont">&#xe6bd;</i>
              </dt>
              <dd>
                LIVE BACCARAT{" "}
                <span>미녀 딜러들과 함께 세계 최고의 카지노를 즐겨보세요!</span>
              </dd>
            </dl>
          </h3>
          <div className="img-list2">
            <div className="clearfix sc-inner">{this.RenderCasinoGame()}</div>
          </div>
          <div className="text-center mt10">
            <a
              href="javascript:;"
              className="all-btn btn"
              onClick={() => {
                this.props.updateContentPage(popupView.casino);
              }}
            >
              <span>전체보기</span>
            </a>
          </div>
          <div className="main-floor1">
            <h3 className="left-title">
              <i className="iconfont">&#xe64a;</i> 주간 출금랭킹 TOP1
            </h3>
            <dl className="left-txt01">
              <dt>
                <span>1</span>
              </dt>
              <dd>
                <p className="tit1">asd1823</p>
                <p className="tit2 sum">
                  <span className="unit">￦</span>{" "}
                  <span
                    className="timer"
                    id="count-number"
                    data-to="87912300"
                    data-speed="1000"
                  ></span>
                </p>
              </dd>
            </dl>
            <h3 className="left-title">
              <i className="iconfont">&#xe62f;</i> HOUSE MONEY
            </h3>
            <dl className="left-txt01 red">
              <dd>
                <p className="tit2 mt0">
                  <div id="odometer" className="odometer2"></div>
                </p>
              </dd>
            </dl>
          </div>

          <div className="main-floor6 clearfix">
            <div className="notice-box">
              <h3 className="tit">
                BILLION<span>실시간 출금현황</span>
              </h3>
              <div className="mb-slide-scrolling-wrap">
                <div
                  className="mb-slide-scrolling-box"
                  id="mb_application_srolling3"
                >
                  <div
                    className="mb-application-srolling-wrap"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    {this.state.widhdraws.map((info: any) => (
                      <div className="mb-text-slide-item-box">
                        <ul>
                          <li>{info.time}</li>
                          <li style={{ textAlign: "left" }}>{info.id}</li>
                          <li style={{ color: "#f1c71f" }}>{info.price}</li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="notice-box">
              <h3 className="tit">
                BILLION<span>공지사항 </span>{" "}
                <a
                  href="javascript:;"
                  className="more-btn"
                  onClick={() => {
                    this.props.updateContentPage(popupView.notice);
                  }}
                >
                  더보기 +
                </a>
              </h3>
              <div className="notice-list">
                <ul>
                  {this.state.notices.map((info: any, index: any) => {
                    if (index > 4) {
                      return <></>;
                    }
                    return (
                      <li>
                        <a href="javascript:;">
                          <p className="tit">{info.title}</p>
                          <p className="day">{ConvertDate(info.regdate)}</p>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="notice-box">
              <h3 className="tit">
                BILLION<span>고객센터 </span>{" "}
                <a
                  href="javascript:;"
                  className="more-btn"
                  onClick={() => {
                    this.props.updateContentPage(popupView.help);
                  }}
                >
                  더보기 +
                </a>
              </h3>
              <div className="notice-list">
                <ul>
                  {this.state.helps.map((info: any, index: any) => {
                    if (index > 4) {
                      return <></>;
                    }

                    return (
                      <li>
                        <a href="javascript:;">
                          <p className="tit">{info.title}</p>
                          <p className="day">{ConvertDate(info.regdate)}</p>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="main-floor2">
            <h3 className="left-title2">
              고객센터 <span>24시간 언제든지 문의가능!</span>
            </h3>
            <div className="left-talk">
              <dl className="orange-bg">
                <dt>
                  <i className="iconfont">&#xe62d;</i>
                </dt>
                <dd>
                  <p className="tit">카카오톡 ID</p>
                  <p className="txt"></p>
                </dd>
              </dl>
              <dl className="blue-bg">
                <dt>
                  <i className="iconfont">&#xe63f;</i>
                </dt>
                <dd>
                  <p className="tit">텔레그램 ID</p>
                  <p className="txt"></p>
                </dd>
              </dl>
              <dl className="green-bg">
                <dt>
                  <i className="iconfont">&#xe63f;</i>
                </dt>
                <dd>
                  <p className="tit">영업총판문의</p>
                  <p className="txt"></p>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        {this.RenderPopup()}
      </>
    );
  }
}
