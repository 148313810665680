import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Menu } from "./menu";
import { Companylist } from "./companylist";
import Marquee from "react-fast-marquee";
import { ConvertDate } from "../utility/help";
import { DonutSmall } from "@material-ui/icons";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  menu = "menu",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  updateContentPage: (page: string) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
  gameEName: any;
  notices: any;
  menuFlag: boolean;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
      gameEName: "",
      notices: [],
      menuFlag: false,
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(30000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {

    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
          });

          this.props.updateContentPage(popupView.note);
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.menu) {
        return (
          <Menu
            handleClose={this.handleClosePopup}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
          ></Menu>
        );
      }

      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
            gameEName={this.state.gameEName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="header-tp before-login-box">
              <div className="menu">
                <a href="javascript:;">
                  <span className="icon iconfont">&#xe614;</span>
                </a>
              </div>
              <h1 className="logo">
                <a href="javascript:;">
                  <img src="/include/images/logo.png" alt="" />
                </a>
              </h1>
              <div className="login-btn">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.login }); //
                  }}
                >
                  로그인
                </a>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="header-tp logging-box">
            <div className="menu">
              <a
                href="javascript:;"
                onClick={() => {
                  if (this.state.menuFlag) {
                    this.setState({ menuFlag: false });
                  } else {
                    this.setState({ menuFlag: true });
                  }
                }}
              >
                <span className="icon iconfont">&#xe614;</span>
              </a>
            </div>
            <h1 className="logo">
              <a
                href="javascript:;"
                onClick={() => {
                  this.props.updateContentPage(popupView.none);
                }}
              >
                <img src="/include/images/logo.png" alt="" />
              </a>
            </h1>

            <div className="memo">
              <a
                href="javascript:;"
                onClick={() => {
                  this.props.updateContentPage(popupView.note);
                }}
              >
                <img src="/include/images/message.gif" alt="" />
                <span>
                  <b></b>
                </span>
              </a>
            </div>
          </div>
          <div className="header-bt">
            <ul>
              <li>
                <span className="icon iconfont">&#xe6cc;</span> 보유머니:{" "}
                <span className="yellow" id="myCash">
                  {ConverMoeny(this.props.user.balance  + this.props.user.in_balance)}
                </span>{" "}
                원{" "}
                {/* <a href="javascript:;">
                  <span className="icon iconfont pink">&#xe648;</span>
                </a> */}
              </li>
              <li>
                <span className="icon iconfont">&#xe9bb;</span> 금고머니:{" "}
                <span className="red" id="tmpPoint">
                  {ConverMoeny(this.props.user.point)}
                </span>{" "}
                원{" "}
                {/* <a href="javascript:;">
                  <span className="icon iconfont grey">&#xe665;</span>
                </a> */}
              </li>
            </ul>
          </div>
        </>
      );
    };

    return (
      <>
        <div
          className="menu-box"
          // style={{ left: this.state.menuFlag ? "0px" : "-100%" }}
        >
          <div className="nenu-tp">
            <div className="name">
              <div className="box">
                <span className="icon iconfont">&#xe808;</span>
                <b className="white">{this.props.user.id}</b> 님,
              </div>
            </div>
            <div className="logout">
              <a
                href="javascript:;"
                className="btn orange-line sh logout"
                onClick={() => {
                  this.props.tryLoginOut();
                }}
              >
                <span className="orange">로그아웃</span>
              </a>
            </div>
            <div className="close">
              <a
                href="javascript:;"
                onClick={() => {
                  this.setState({ menuFlag: false });
                }}
              >
                <img src="/include/images/nav-close.png" alt="" />
              </a>
            </div>
          </div>
          <nav>
            <ul className="menu-deaph1 clearfix">
              <li>
                <a href="javascript:;" className="m-on">
                  <span className="icon iconfont">&#xe676;</span>슬롯
                </a>
                <ul
                  className="menu-deaph2 clearfix"
                  style={{ display: "block" }}
                >
                  {this.state.slots.map((info: any, index: any) => (
                    <>
                      <li
                        key={`key${info.nameEn}kkk`}
                        onClick={() => {
                          if (this.props.authenticated == false) {
                            confirmAlert({
                              title: "로그인",
                              message: "로그인 이후 이용가능한 기능입니다",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => {
                                    this.setState({
                                      mode: Mode.none,
                                    });
                                  },
                                },
                              ],
                            });
                            return;
                          }

                          if (info.used === "y") {
                            this.setState({ gameCode: info.code });
                            this.setState({ gameName: info.nameKo });
                            this.setState({ gameEName: info.nameEn });
                            this.setState({ mode: Mode.slot });
                            this.setState({ popupStatuses: popupView.game });
                          } else {
                            confirmAlert({
                              title: "점검중",
                              message: "현재 해당게임은 점검중입니다 .",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }
                        }}
                      >
                        <a href="javascript:;">{info.nameKo}</a>
                      </li>
                    </>
                  ))}
                </ul>
              </li>
              <li>
                <a href="javascript:;">
                  <span className="icon iconfont">&#xe61c;</span>카지노
                </a>
                <ul className="menu-deaph2">
                  {this.state.casionos.map((info: any, index: any) => (
                    <>
                      <li
                        key={`key${info.nameEn}kkk`}
                        onClick={() => {
                          if (this.props.authenticated == false) {
                            confirmAlert({
                              title: "로그인",
                              message: "로그인 이후 이용가능한 기능입니다",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => {
                                    this.setState({
                                      popupStatuses: popupView.none,
                                    });
                                  },
                                },
                              ],
                            });
                            return;
                          }

                          if (info.used === "y") {
                            this.handleOpenSlot(info.code, info.gameCompany);
                          } else {
                            confirmAlert({
                              title: "점검중",
                              message: "현재 해당게임은 점검중입니다 .",
                              buttons: [
                                {
                                  label: "확인",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }
                        }}
                      >
                        <a href="javascript:;">{info.nameKo}</a>
                      </li>
                    </>
                  ))}
                </ul>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.deposit);
                  }}
                >
                  <span className="icon iconfont">&#xe601;</span>입금신청
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.withdraw);
                  }}
                >
                  <span className="icon iconfont">&#xe89f;</span>출금신청
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.point);
                  }}
                >
                  <span className="icon iconfont">&#xe601;</span>금고
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.comp);
                  }}
                >
                  <span className="icon iconfont">&#xe89f;</span>콤프
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.notice);
                  }}
                >
                  <span className="icon iconfont">&#xe60a;</span>공지사항
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.user);
                  }}
                >
                  <span className="icon iconfont ">&#xe633;</span>마이페이지
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className="close-menu"
                  onClick={() => {
                    this.props.updateContentPage(popupView.help);
                  }}
                >
                  <span className={this.props.activeHelp ? 'icon iconfont neon' : 'icon iconfont '} >&#xe608;</span>고객센터
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div id="header">{RenderLogin()}</div>
        <div className="notice" style={{ marginLeft: "10px" }}>
          <p className="tit">
            <span>
              <b>NOTICE</b>
            </span>
          </p>
          <div className="main-visual">
            <ul className="bxslider">
              <li>
                <a href="javascript:;">
                  ★ 입금계좌변경 될 수있으니 매번 입금시 꼭 계좌문의
                  부탁드립니다! ★
                </a>
              </li>{" "}
            </ul>
          </div>
        </div>

        {RenderPopup()}
        {/* {RendePopup()} */}
      </>
    );
  }
}
