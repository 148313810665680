import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import Marquee from "react-fast-marquee";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  gameView: string;
  updateUser: () => any;
  updateContentPage: (page: string) => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  gameEName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
  yesterdayStr: string;
  hover: boolean;
  hoverindex: number;
}

export class Slot extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      gameEName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
      yesterdayStr: "",
      hover: false,
      hoverindex: -1,
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    this.setState({ yesterdayStr: yesterdayStr });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className={
              this.state.hover && index != this.state.hoverindex
                ? "slot-btn gl-title-click after-hover"
                : "slot-btn gl-title-click"
            }
            onMouseEnter={() =>
              this.setState({ hover: true, hoverindex: index })
            }
            onMouseLeave={() => this.setState({ hover: false, hoverindex: -1 })}
            key={`key${info.nameEn}kkk`}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.setState({ gameEName: info.nameEn });
                this.setState({ mode: Mode.slot });
                // this.handleSlotGames(info.code);
                this.setState({ popupStatuses: popupView.game });
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="inner">
              <img
                className="slot-bg"
                src="/include/images/main/img-list-bg.png"
              />
              <div className="hover-bg">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="slot-cont">
                <img className="slot-img" src={info.mobileImg} />
              </div>
            </div>
            <p className="game_title_txt">{info.nameKo}</p>
          </a>
        ))}
      </>
    );
  };

  RenderPopup = () => {
    if (this.state.popupStatuses === popupView.game) {
      if (this.props.authenticated === false) {
        confirmAlert({
          title: "로그인",
          message: "로그인 이후 이용가능한 기능입니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.setState({ popupStatuses: popupView.none });
              },
            },
          ],
        });
        return;
      }

      return (
        <Companylist
          handleClose={this.handleClosePopup}
          handleChange={this.handleChangePopup}
          gameCode={this.state.gameCode}
          gameName={this.state.gameName}
          gameEName={this.state.gameEName}
        ></Companylist>
      );
    }

    return <div></div>;
  };

  render() {
    const authenticated = this.props.authenticated;

    //

    return (
      <>
        <div id="container">
          <div className="sub-container">
            <div className="progressive-jackpot clearfix">
              <h3 className="tit">PROGRESSIVE JACKPOT</h3>
              <div
                id="odometer"
                className="odometer2 odometer odometer-theme-car"
              ></div>
            </div>
            <h3 className="main-title">
              <i className="icon iconfont"></i> SLOT GAME{" "}
              <span>
                50여종의 슬롯게임사들 가운데 유저들이 가장 많이 이용하는
                슬롯게임 입니다.
              </span>
            </h3>
            <div className="img-list">
              <div className="clearfix sc-inner">{this.RenderSlotList()}</div>
            </div>
          </div>
        </div>

        {this.RenderPopup()}
      </>
    );
  }
}
