import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
  gameView: string;
  updateContentPage: (page: string) => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
}

export class Left extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {

      let time = new Date().getTime() / 1000;

        for (;;) {
          let now = new Date().getTime() / 1000;

          const localTime = localStorage.getItem('UpdateNote');
          if (localTime != null && now - Number(localTime) < 10) {
            await sleep(1000);
            continue
          }

          
          if (now - Number(time) < 10) {
            await sleep(1000);
            continue;
          }
          localStorage.setItem('UpdateNote', now.toString());


          this.handleUpdateNote();

          await sleep(10000);
        }
      } catch (ex) {}


    });
    this.handleUpdateNote();
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
    
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        } else if (this.state.popupStatuses !== popupView.note) {
          this.setState({
            postCount: 0,
            note: [],
          });
        }

        // if (this.state.postCount > 0) {
        //   this.setState({ popupStatuses: popupView.note });
        // }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated === true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    return (
      <>
        <div className="wraper-left">
          <div className="nav">
            <div className="logo">
              <a
                href="javascript:;"
                onClick={() => {
                  this.props.updateContentPage(popupView.none);
                }}
              >
                <img src="/include/images/logo.png?v=2" alt="" />
              </a>
            </div>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  className=""
                  onClick={() => {
                    this.props.updateContentPage(popupView.none);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>Home</div>
                  <div></div>
                  <div>Home</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.slot);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>슬롯게임</div>
                  <div></div>
                  <div>슬롯게임</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.casino);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>카지노</div>
                  <div></div>
                  <div>카지노</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className=""
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.deposit);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>입금신청</div>
                  <div></div>
                  <div>입금신청</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className=""
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.withdraw);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>출금신청</div>
                  <div></div>
                  <div>출금신청</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.point);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>금고</div>
                  <div></div>
                  <div>금고</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.comp);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>콤프</div>
                  <div></div>
                  <div>콤프</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.notice);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>공지사항</div>
                  <div></div>
                  <div>공지사항</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.user);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div>마이페이지</div>
                  <div></div>
                  <div>마이페이지</div>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }
                    this.props.updateContentPage(popupView.help);
                  }}
                >
                  <span>
                    <i className="iconfont"></i>
                  </span>
                  <div className={this.props.activeHelp ? 'neon' : ''}>고객센터</div>
                  <div></div>
                  <div className={this.props.activeHelp ? 'neon' : ''}>고객센터</div>
                </a>
              </li>
            </ul>
            <h3 className="left-title">
              <i className="iconfont"></i> 주간 출금랭킹 TOP1
            </h3>
            <dl className="left-txt01">
              <dt>
                <span>1</span>
              </dt>
              <dd>
                <p className="tit1">asd1823</p>
                <p className="tit2 sum">
                  <span className="unit">￦</span>{" "}
                  <span
                    className="timer"
                    id="count-number"
                    data-to="87912300"
                    data-speed="1000"
                  >
                    87,912,300
                  </span>
                </p>
              </dd>
            </dl>
            <h3 className="left-title">
              <i className="iconfont"></i> HOUSE MONEY
            </h3>
            <dl className="left-txt01 red">
              <dd>
                <p className="tit2 mt0">
                  <div id="odometer" className="odometer"></div>
                </p>
              </dd>
            </dl>
            <h3 className="left-title2">
              고객센터 <span>24시간 언제든지 문의가능!</span>
            </h3>
            <div className="left-talk">
              <dl className="orange-bg">
                <dt>
                  <i className="iconfont"></i>
                </dt>
                <dd>
                  <p className="tit">카카오톡 ID</p>
                  <p className="txt"></p>
                </dd>
              </dl>
              <dl className="blue-bg">
                <dt>
                  <i className="iconfont"></i>
                </dt>
                <dd>
                  <p className="tit">텔레그램 ID</p>
                  <p className="txt"></p>
                </dd>
              </dl>
              <dl className="green-bg">
                <dt>
                  <i className="iconfont"></i>
                </dt>
                <dd>
                  <p className="tit">영업총판문의</p>
                  <p className="txt"></p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </>
    );
  }
}
