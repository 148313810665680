import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { MiniService } from "../service/mini.service";
import { Header } from "./header";
import { Contents } from "./contents";
import { Footer } from "./footer";
import { Right } from "./right";
import { Left } from "./left";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casino = "casino",
}

interface Props {
  authenticated: boolean;
  activeHelp: boolean;
  session: any;
  user: any;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  gameView: string;
  SetCookie: (name: string, data: any) => any;
}

interface State {
  contentPage: string;
  history: any;
}

export class Main extends Component<Props, State> {
  divElement: any;
  miniService = new MiniService();

  constructor(props: Props) {
    super(props);
    this.state = {
      contentPage: popupView.none,
      history: "",
    };
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({});
    }
  }

  createData = (
    name: any,
    balnce: any,
    fat: any,
    maxBalanceCount: any,
    carbs: any,
    protein: any
  ) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    };
  };

  updateUserDate = () => {};

  updateMiniHistroy = (page: any) => {
    this.miniService.getMiniGameBetList(page).then((s: any) => {
      if (s.status === "success") {
        this.setState({ history: s.minis });
      }
    });
  };

  updateNowDate = (currency: string, minute: number) => {};

  updateGameDate = (currency: string, minute: number) => {};

  handleDobet = (episode: string) => {};

  updateContentPage = (page: string) => {
    this.setState({
      contentPage: page,
    });
  };

  render() {
    return (
      <>
        <div id="wraper">
          <Left
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
            updateUser={this.props.updateUser}
            gameView={this.props.gameView}
            updateContentPage={this.updateContentPage}
          ></Left>
          <Right
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
            updateUser={this.props.updateUser}
            gameView={this.props.gameView}
            SetCookie={this.props.SetCookie}
            contentPage={this.state.contentPage}
            updateContentPage={this.updateContentPage}
          ></Right>
          {/* <Header
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
            updateUser={this.props.updateUser}
            gameView={this.props.gameView}
            SetCookie={this.props.SetCookie}
          ></Header>

          <Contents
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
            gameView={this.props.gameView}
            updateUser={this.props.updateUser}
          ></Contents>
          <Footer></Footer> */}
        </div>
      </>
    );
  }
}
