import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  comp = "comp",
  reg = "reg",
  login = "login",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };

    console.log(this.props.user);
  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                // window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "입금",
        message: "입금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "입금",
        message: "최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "입금",
        message: "입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserDeposit(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "입금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "입금",
          message: "대기중인 입금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "입금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <div id="container">
        <div className="sub-container">
          <div className="min-box">
            <h3 className="sub-title">
              입금신청 <span></span>
            </h3>
            <div className="notandum-box bg1">
              <h3 className="tit">
                <span className="icon iconfont"></span> 주의사항
              </h3>
              <div className="txt">
                ① 보이스피싱 및 3자사기로 협박하더라도 협상 및 타협 절대없음{" "}
                <br />
                ② 계좌거래시 입금/출금 이름이 동일해야 입출금이 가능하오니
                정확히 입력해 주세요.
                <br />③ 수표나 타인계좌로 이름변경하여 입금시 머니몰수 및
                아이디정지 처리되오니 타인명의로 입금해야될시 문의를 먼저하시기
                바랍니다.
              </div>
            </div>
            <div className="application-style">
              <dl>
                <dt>계좌문의</dt>
                <dd>
                  <a
                    href="javascript:;"
                    className="btn pink w113 sh"
                    onClick={() => this.handleAsk()}
                  >
                    <span>입금계좌요청</span>
                  </a>{" "}
                  <p className="white">
                    * 입금하시기 전에 반드시 계좌문의를 해주세요.{" "}
                  </p>
                  <p className="yellow">(최소 충전금액은 3만원 입니다.) </p>
                </dd>
              </dl>
              <dl>
                <dt>아이디</dt>
                <dd>
                  <input type="text" value={this.props.user.id} readOnly />
                </dd>
              </dl>
              <dl>
                <dt>예금주명</dt>
                <dd>
                  <input
                    type="text"
                    value={this.props.user.bankowner}
                    readOnly
                  />
                </dd>
              </dl>
              <dl>
                <dt>회원은행</dt>
                <dd>
                  <input
                    type="text"
                    value={this.props.user.bankname}
                    readOnly
                  />
                </dd>
              </dl>
              <dl>
                <dt>신청금액</dt>
                <dd className="deposit-name">
                  <input
                    type="text"
                    readOnly
                    placeholder="0"
                    value={this.state.balance}
                    onFocus={() => this.setState({ balance: "" })}
                    onChange={(e) =>
                      this.setState({
                        balance: e.target.value,
                      })
                    }
                  />
                  <a
                    href="javascript:;"
                    className="first"
                    onClick={() => {
                      this.setState({ balance: "0" });
                    }}
                  >
                    초기화
                  </a>
                  <p className="btn-all">
                    <a
                      href="javascript:;"
                      onClick={() => {
                        this.setState({
                          balance: `${Number(this.state.balance) + 10000}`,
                        });
                      }}
                    >
                      1만원
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => {
                        this.setState({
                          balance: `${Number(this.state.balance) + 50000}`,
                        });
                      }}
                    >
                      5만원
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => {
                        this.setState({
                          balance: `${Number(this.state.balance) + 100000}`,
                        });
                      }}
                    >
                      10만원
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => {
                        this.setState({
                          balance: `${Number(this.state.balance) + 500000}`,
                        });
                      }}
                    >
                      50만원
                    </a>
                  </p>
                </dd>
              </dl>
            </div>

            <div className="center-btn">
              <a
                href="javascript:;"
                className="btn orange sh w240"
                onClick={this.handleDoDeposit}
              >
                <span>입금신청</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
