import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleDoWithdraw = () => {
    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message:
            "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <>
        <div id="container">
          <div className="sub-container">
            <div className="min-box">
              <h3 className="sub-title">
                출금신청 <span></span>
              </h3>
              <div className="notandum-box bg2">
                <h3 className="tit">
                  <span className="icon iconfont"></span> 주의사항
                </h3>
                <div className="txt">
                  ① 입금자명과 출금자명이 다를 경우 본인확인 요청이 있을 수
                  있습니다.
                </div>
              </div>
              <div className="application-style">
                <dl>
                  <dt>아이디</dt>
                  <dd>
                    <input type="text" value={this.props.user.id} readOnly />
                  </dd>
                </dl>
                <dl>
                  <dt>예금주명</dt>
                  <dd>
                    <input
                      type="text"
                      value={this.props.user.bankowner}
                      readOnly
                    />
                  </dd>
                </dl>
                <dl>
                  <dt>회원은행</dt>
                  <dd>
                    <input
                      type="text"
                      value={this.props.user.bankname}
                      readOnly
                    />
                  </dd>
                </dl>
                <dl>
                  <dt>요청금액</dt>
                  <dd>
                    <input
                      type="text"
                      readOnly
                      placeholder="0"
                      value={this.state.balance}
                      onFocus={() => this.setState({ balance: "" })}
                      onChange={(e) =>
                        this.setState({
                          balance: e.target.value,
                        })
                      }
                    />
                    <span className="btn-all">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 10000}`,
                          });
                        }}
                      >
                        1만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 50000}`,
                          });
                        }}
                      >
                        5만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 100000}`,
                          });
                        }}
                      >
                        10만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 500000}`,
                          });
                        }}
                      >
                        50만원
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.setState({
                            balance: `${Number(this.state.balance) + 1000000}`,
                          });
                        }}
                      >
                        100만원
                      </a>
                      <a
                        href="javascript:;"
                        className="first"
                        onClick={() => {
                          this.setState({ balance: "0" });
                        }}
                      >
                        초기화
                      </a>
                    </span>
                  </dd>
                </dl>
              </div>

              <div className="center-btn">
                <a
                  href="javascript:;"
                  className="btn orange sh w240"
                  onClick={this.handleDoWithdraw}
                >
                  <span>출금신청</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
      // <Popup
      //   open={true}
      //   contentStyle={{
      //     zIndex: 99,
      //     background: "none",
      //     border: "none",
      //     width: "none",
      //   }}
      //   overlayStyle={{
      //     overflow: "scroll",
      //   }}
      // >
      //   {(close) => (
      //     <>
      //       <div
      //         id="sub_pop1"
      //         className="popup_style04 popup_none popup_content"
      //         style={{
      //           opacity: "1",
      //           visibility: "visible",
      //           display: "inline-block",
      //           outline: "none",
      //           transition: "all 0.3s ease 0s",
      //           textAlign: "left",
      //           position: "relative",
      //           verticalAlign: "middle",
      //         }}
      //       >
      //         <div className="popup_wrap">
      //           <div className="close_box">
      //             <a
      //               href="javascript:;"
      //               className="sub_pop1_close"
      //               onClick={() => {
      //                 this.props.handleClose();
      //               }}
      //             >
      //               <img src="images/popup_close.png" />
      //             </a>
      //           </div>
      //           <div className="popupbox">
      //             <div className="popup_tab_wrap">
      //               <ul className="popup_tab popup_tab1">
      //                 <li className="tab1 ">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.deposit);
      //                     }}
      //                   >
      //                     <span>입금신청</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab2 sk_tab_active_01">
      //                   <a href="javascript:;">
      //                     <span>출금신청</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab3">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.point);
      //                     }}
      //                   >
      //                     <span>금고</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab4">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.comp);
      //                     }}
      //                   >
      //                     <span>콤프</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab5">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.notice);
      //                     }}
      //                   >
      //                     <span>공지사항</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab6">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.user);
      //                     }}
      //                   >
      //                     <span>마이페이지</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab7">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.help);
      //                     }}
      //                   >
      //                     <span>고객센터</span>
      //                   </a>
      //                 </li>
      //                 <li className="tab8">
      //                   <a
      //                     href="javascript:;"
      //                     onClick={() => {
      //                       this.props.handleChange(popupView.note);
      //                     }}
      //                   >
      //                     <span>쪽지</span>
      //                   </a>
      //                 </li>
      //               </ul>
      //             </div>

      //             <div id="sk_tab_con_01_1" className="sk_tab_con_01">
      //               <div id="sk_tab_con_01_1" className="sk_tab_con_01">
      //                 <div className="title1">출금신청 </div>
      //                 <div className="con_box10">
      //                   <div className="info_wrap">
      //                     <div className="info2">주의사항</div>
      //                     <div className="info3">
      //                       - 입금자명과 출금자명이 다를 경우 본인확인 요청이
      //                       있을 수 있습니다.
      //                     </div>
      //                   </div>
      //                 </div>
      //                 <div className="con_box10">
      //                   <div className="money">
      //                     <ul>
      //                       <li style={{ width: "250px", textAlign: "left" }}>
      //                         <img src="images/ww_icon.png" height="26" />{" "}
      //                         보유머니 :{" "}
      //                         <span className="font05" id="myWalletPop">
      //                           {ConverMoeny(this.props.user.balance)}
      //                         </span>
      //                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      //                       </li>
      //                       <li style={{ width: "250px", textAlign: "left" }}>
      //                         <img src="images/ww_icon2.png" height="26" />{" "}
      //                         금고머니 :{" "}
      //                         <span className="font05" id="myCasPop">
      //                           {ConverMoeny(this.props.user.point)}
      //                         </span>
      //                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      //                       </li>
      //                       <li></li>
      //                     </ul>
      //                   </div>
      //                 </div>

      //                 <div className="con_box10">
      //                   <table
      //                     style={{ width: "100%", border: 0 }}
      //                     cellSpacing={0}
      //                     cellPadding={0}
      //                     className="write_title_top"
      //                   >
      //                     <tbody>
      //                       <tr>
      //                         <td className="write_title">아이디</td>

      //                         <td className="write_basic">
      //                           <input
      //                             className="input1"
      //                             size={30}
      //                             value={this.props.user.id}
      //                             readOnly
      //                           />
      //                         </td>
      //                       </tr>

      //                       <tr>
      //                         <td className="write_title">예금주명</td>

      //                         <td className="write_basic">
      //                           <input
      //                             className="input1 userName"
      //                             size={30}
      //                             readOnly
      //                             value={this.props.user.bankowner}
      //                           />
      //                         </td>
      //                       </tr>
      //                       <tr>
      //                         <td className="write_title">회원은행</td>

      //                         <td className="write_basic">
      //                           <input
      //                             className="input1 userBankName"
      //                             size={30}
      //                             readOnly
      //                             value={this.props.user.bankname}
      //                           />
      //                         </td>
      //                       </tr>

      //                       <tr>
      //                         <td className="write_title">신청금액</td>

      //                         <td className="write_basic">
      //                           <input
      //                             className="input1"
      //                             size={30}
      //                             readOnly
      //                             placeholder="0"
      //                             value={this.state.balance}
      //                             onFocus={() => this.setState({ balance: "" })}
      //                             onChange={(e) =>
      //                               this.setState({
      //                                 balance: e.target.value,
      //                               })
      //                             }
      //                           />{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 10000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">1만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 50000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">5만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 100000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">10만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 500000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">50만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 1000000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">100만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 5000000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">500만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({
      //                                 balance: `${
      //                                   Number(this.state.balance) + 10000000
      //                                 }`,
      //                               });
      //                             }}
      //                           >
      //                             <span className="btn1_2">1000만원</span>
      //                           </a>{" "}
      //                           <a
      //                             href="javascript:;"
      //                             onClick={() => {
      //                               this.setState({ balance: "0" });
      //                             }}
      //                           >
      //                             <span className="btn1_1">정정</span>
      //                           </a>
      //                           <br />
      //                           3만원이상 만원단위로 입금신청해주시기 바랍니다.
      //                         </td>
      //                       </tr>
      //                     </tbody>
      //                   </table>
      //                 </div>
      //                 <div className="con_box20">
      //                   <div className="btn_wrap_center">
      //                     <ul>
      //                       <li style={{ width: "30%" }}>
      //                         <a
      //                           href="javascript:;"
      //                           style={{ width: "30%" }}
      //                           onClick={this.handleDoWithdraw}
      //                         >
      //                           <span
      //                             className="btn3_1"
      //                             style={{ width: "30%" }}
      //                           >
      //                             출금신청
      //                           </span>
      //                         </a>
      //                       </li>
      //                     </ul>
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //   )}
      // </Popup>
    );
  }
}
