import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Help } from "./help";
import { Notice } from "./notice";
import { Point } from "./point";
import { Comp } from "./comp";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casino = "casino",
}

interface Props {
  SetCookie: (name: string, data: any) => any;
  authenticated: boolean;
  updateUser: () => any;
  user: any;
  updateContentPage: (page: string) => any;
}
interface State {
  notices: any;
  helps: any;
  widhdraws: any;
  selectBoard: string;
  popupStatuses: string;
}

export class Footer extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      notices: [],
      helps: [],
      widhdraws: [],
      selectBoard: "1",
      popupStatuses: popupView.none,
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일 22시28분";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr },
      { id: "jvd****", price: "83,000,000 원", date: yesterdayStr },
      { id: "vhr**", price: "82,000,000 원", date: yesterdayStr },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr },
      { id: "df****", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  render() {
    const authenticated = this.props.authenticated;

    return (
      <>
        <div id="footer">
          <div className="footer-cont">
            <div className="footer-cont-box">
              <div className="logo">
                <img src="/include/images/logo.png" alt="" />
              </div>
              <div className="footer-txt">
                <p>
                  COPYRIGHT 2021 <strong>FUN</strong>. ALL RIGHTS RESERVED.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-btn">
          <a
            href="javascript:;"
            onClick={() => {
              this.props.updateContentPage(popupView.slot);
            }}
          >
            <span className="icon iconfont">&#xe676;</span>
            <p className="txt">슬롯게임</p>
          </a>
          <a
            href="javascript:;"
            onClick={() => {
              this.props.updateContentPage(popupView.casino);
            }}
          >
            <span className="icon iconfont">&#xe61c;</span>
            <p className="txt">카지노</p>
          </a>
          <a
            href="javascript:;"
            onClick={() => {
              this.props.updateContentPage(popupView.deposit);
            }}
          >
            <span className="icon iconfont">&#xe601;</span>
            <p className="txt">입금신청</p>
          </a>
          <a
            href="javascript:;"
            onClick={() => {
              this.props.updateContentPage(popupView.withdraw);
            }}
          >
            <span className="icon iconfont">&#xe65d;</span>
            <p className="txt">출금신청</p>
          </a>
        </div>
      </>
    );
  }
}
