import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;
  changePopup: (value: any) => any;
  handleChange: (viewName: string) => any;
}

interface State {
  ID: string;
  PW: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup-bg" id="login" style={{ display: "block" }}>
              <div className="popup-box">
                <div className="box">
                  <h3 className="pop-logo">
                    <img src="/include/images/logo.png?v=1" alt="" />
                  </h3>
                  <a
                    href="javascript:;"
                    className="popup-close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  >
                    <img src="/include/images/popup-close.gif" alt="" />
                  </a>
                  <h4 className="tit">아이디</h4>
                  <input
                    type="text"
                    placeholder="아이디"
                    onChange={({ target: { value } }) =>
                      this.setState({ ID: value })
                    }
                  />
                  <h4 className="tit">비밀번호</h4>
                  <input
                    type="password"
                    placeholder="비밀번호"
                    onChange={({ target: { value } }) =>
                      this.setState({ PW: value })
                    }
                  />
                  <div style={{ width: "215px", margin: "auto" }}>
                    <br />
                    <br />
                    {/* <a href="javascript:void(0)"  >
						<img id="captcha_img" border="1" src="/Captcha/getCaptcha?r=17145" style="width: 100px; height: 30px; ">
					</a>
                    <input
                      type="text"
                      name="authcode"
                      id="authcode"
                      placeholder="보안코드"
                      style={{
                        lineHeight: "28px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    /> */}
                  </div>
                  <div className="pop-btn">
                    <a
                      href="javascript:;"
                      className="btn orange sh"
                      onClick={() => {
                        if (
                          this.state.ID == null ||
                          this.state.ID == "" ||
                          this.state.PW == "" ||
                          this.state.PW == null
                        ) {
                          return;
                        }
                        this.props.tryLogin(this.state.ID, this.state.PW);
                      }}
                    >
                      <span>로그인</span>
                    </a>
                  </div>
                  <p className="txt">
                    아직도 회원이 아니신가요?{" "}
                    <a
                      href="javascript:;"
                      className="go-recommender-code"
                      onClick={() => {
                        this.props.handleChange(popupView.reg); //회원가입
                      }}
                    >
                      무료회원가입
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className="modal loginModal fade show"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog size-sm modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>로그인</h5>
                      <span>LOGIN</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-container">
                      <div className="form-head">
                        <span>
                          <i className="fab fa-fort-awesome"></i> Ocean 슬롯에
                          오신것을 환영합니다.
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>- 아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="아이디"
                            onChange={({ target: { value } }) =>
                              this.setState({ ID: value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>- 비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            type="password"
                            placeholder="비밀번호"
                            onChange={({ target: { value } }) =>
                              this.setState({ PW: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button
                          className="login-btn"
                          data-dismiss="modal"
                          onClick={() => {
                            if (
                              this.state.ID == null ||
                              this.state.ID == "" ||
                              this.state.PW == "" ||
                              this.state.PW == null
                            ) {
                              return;
                            }
                            this.props.tryLogin(this.state.ID, this.state.PW);
                          }}
                        >
                          <i className="fas fa-unlock-alt"></i> 로그인
                        </button>
                        <button
                          className="gray-bg"
                          onClick={() => {
                            this.props.handleChange(popupView.reg); //회원가입
                          }}
                        >
                          <i className="fa fa-user-plus"></i> 회원가입
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
