import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { MiniService } from "../service/mini.service";
import { Header } from "./header";
import { Contents } from "./contents";
import { Footer } from "./footer";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Slot } from "./slot";
import { Casino } from "./casino";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casino = "casino",
}

interface Props {
  authenticated: boolean;
  activeHelp: boolean;
  user: any;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  gameView: string;
  SetCookie: (name: string, data: any) => any;
  contentPage: string;
  updateContentPage: (page: string) => any;
}

interface State {
  history: string;
  note: any;
}

export class Right extends Component<Props, State> {
  divElement: any;
  miniService = new MiniService();

  constructor(props: Props) {
    super(props);
    this.state = {
      history: "",
      note: [],
    };
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({});
    }
  }

  createData = (
    name: any,
    balnce: any,
    fat: any,
    maxBalanceCount: any,
    carbs: any,
    protein: any
  ) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    };
  };

  updateUserDate = () => {};

  updateMiniHistroy = (page: any) => {
    this.miniService.getMiniGameBetList(page).then((s: any) => {
      if (s.status === "success") {
        this.setState({ history: s.minis });
      }
    });
  };

  updateNowDate = (currency: string, minute: number) => {};

  updateGameDate = (currency: string, minute: number) => {};

  handleDobet = (episode: string) => {};

  // updateContentPage = (page: string) => {
  //   this.setState({ contentPage: page });
  // };

  updateNote = (note: any) => {
    this.setState({ note: note });
  };

  render() {
    const RenderContent = () => {
      if (this.props.contentPage === popupView.slot) {
        return (
          <>
            <Slot
              activeHelp={this.props.activeHelp}
              tryLogin={this.props.tryLogin}
              tryLoginOut={this.props.tryLoginOut}
              user={this.props.user}
              authenticated={this.props.authenticated}
              gameView={this.props.gameView}
              updateUser={this.props.updateUser}
              updateContentPage={this.props.updateContentPage}
            ></Slot>
          </>
        );
      }
      if (this.props.contentPage === popupView.casino) {
        return (
          <>
            <Casino
              activeHelp={this.props.activeHelp}
              tryLogin={this.props.tryLogin}
              tryLoginOut={this.props.tryLoginOut}
              user={this.props.user}
              authenticated={this.props.authenticated}
              gameView={this.props.gameView}
              updateUser={this.props.updateUser}
              updateContentPage={this.props.updateContentPage}
            ></Casino>
          </>
        );
      }
      if (this.props.contentPage === popupView.deposit) {
        return (
          <>
            <Deposit
              user={this.props.user}
              updateUser={this.props.updateUser}
            ></Deposit>
          </>
        );
      }
      if (this.props.contentPage === popupView.withdraw) {
        return (
          <>
            <Withdraw
              user={this.props.user}
              updateUser={this.props.updateUser}
            ></Withdraw>
          </>
        );
      }
      if (this.props.contentPage === popupView.point) {
        return (
          <>
            <Point
              user={this.props.user}
              updateUser={this.props.updateUser}
            ></Point>
          </>
        );
      }
      if (this.props.contentPage === popupView.comp) {
        return (
          <>
            <Comp
              user={this.props.user}
              updateUser={this.props.updateUser}
            ></Comp>
          </>
        );
      }
      if (this.props.contentPage === popupView.notice) {
        return (
          <>
            <Notice></Notice>
          </>
        );
      }
      if (this.props.contentPage === popupView.user) {
        return (
          <>
            <User user={this.props.user}></User>
          </>
        );
      }
      if (this.props.contentPage === popupView.help) {
        return (
          <>
            <Help></Help>
          </>
        );
      }
      if (this.props.contentPage === popupView.note) {
        return (
          <>
            <Note note={this.state.note}></Note>
          </>
        );
      }

      return (
        <Contents
          activeHelp={this.props.activeHelp}
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
          gameView={this.props.gameView}
          updateUser={this.props.updateUser}
          updateContentPage={this.props.updateContentPage}
        ></Contents>
      );
    };

    return (
      <>
        <div className="wraper-right">
          <Header
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
            updateUser={this.props.updateUser}
            gameView={this.props.gameView}
            SetCookie={this.props.SetCookie}
            updateContentPage={this.props.updateContentPage}
            updateNote={this.updateNote}
          ></Header>

          {RenderContent()}

          <Footer></Footer>
        </div>
      </>
    );
  }
}
