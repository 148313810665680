import { Height } from "@material-ui/icons";
import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  gameEName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any, index: any) => {
    return (
      <>
        <li>
          <div className="box">
            <i className="img">
              <img src={info.imgUrl} alt="" />
            </i>
            <p className="txt">{info.nameKo}</p>
          </div>
          <div className="hover-box">
            <a
              href="javascript:;"
              className="btn orange-line2 sh"
              onClick={() => {
                this.handleOpenSlot(info.code, info.gameCompany);
              }}
            >
              <span>게임하기</span>
            </a>
          </div>
        </li>
      </>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="game-list">
              <div className="cq9-game-list">
                <h1 className="game-list-logo">
                  <div className="box">
                    <span>{this.props.gameName}</span>
                  </div>

                  <a
                    href="javascript:;"
                    className="popup-close"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                    style={{ position: "fixed", top: "30px", left: "10px" }}
                  >
                    <img src="/include/images/popup-close.gif" alt="" />
                  </a>
                </h1>
                <div className="list">
                  <ul className="clearfix">
                    {this.state.games.map((row: any, index: any) =>
                      this.RenderGame(row, index)
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
